.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.IconsWrapper {
  display: flex;
  flex-direction: row;
  min-width: 50vw;
  justify-content: space-around;
  margin-top: 100px;
}
.Icon:hover {
  color: #FFFFFF60;
}